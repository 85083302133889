export default {
  closedDeals: () => {
    return {
      title: "Total closed deals",
      amount: 0,
      link: "sales/details",
      option: {
        chartType: "line",
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "DEAL COUNTS",
          nameGap: 40,
        },
        series: [],
      },
    };
  },

  closedDealsByModel: () => {
    return {
      title: "Total closed deals by models",
      amount: 0,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "DEAL COUNTS",
          nameGap: 40,
        },
        series: [],
      },
    };
  },

  closedDealsByProductTypes: () => {
    return {
      title: "Total closed deals by product types",
      amount: 0,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "DEAL COUNTS",
          nameGap: 40,
        },
        series: [],
      },
    };
  },

  closedDealsByReps: () => {
    return {
      title: "Total closed deals by reps",
      amount: 0,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "DEAL COUNTS",
          nameGap: 36,
        },
        series: [],
      },
    };
  },

  salesRevenue: () => {
    return {
      title: "Total sales revenue",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "line",
        grid: {
          right: "5%",
          top: "5%",
        },
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "SUM OF SALES VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 90,
        },
        series: [],
      },
    };
  },

  salesRevenueByModel: () => {
    return {
      title: "Total sales revenue by models",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF SALES VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 90,
        },
        series: [],
      },
    };
  },

  salesRevenuesByProductTypes: () => {
    return {
      title: "Total sales revenue by product types",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF SALES VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 90,
        },
        series: [],
      },
    };
  },

  salesRevenuesByReps: () => {
    return {
      title: "Total sales revenue by reps",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF SALES VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 90,
        },
        series: [],
      },
    };
  },

  premiumSalesRevenue: () => {
    return {
      title: "Total premium sales revenue",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "line",
        grid: {
          right: "5%",
          top: "5%",
        },
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "SUM OF PREMIUM VALUE",
          nameGap: 80,
          axisLabel: {
            formatter: "${value}",
          },
        },
        series: [],
      },
    };
  },

  premiumSalesRevenueByTypes: () => {
    return {
      title: "Total premium sales revenue by types",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF PREMIUM VALUE",
          nameGap: 80,
          axisLabel: {
            formatter: "${value}",
          },
        },
        series: [],
      },
    };
  },

  addOnSalesRevenue: () => {
    return {
      title: "Total add on sales revenue",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "line",
        grid: {
          right: "5%",
          top: "5%",
        },
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "SUM OF ADD ON VALUE",
          nameGap: 80,
          axisLabel: {
            formatter: "${value}",
          },
        },
        series: [],
      },
    };
  },

  addOnSalesRevenueByTypes: () => {
    return {
      title: "Total add on sales revenue by types",
      amount: 0,
      amountPrefix: "$",
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF ADD ON VALUE",
          nameGap: 80,
          axisLabel: {
            formatter: "${value}",
          },
        },
        series: [],
      },
    };
  },

  averageDealValue: () => {
    return {
      title: "Average deal value",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        timeSpan: true,
        chartType: "line",
        grid: {
          right: "5%",
          top: "5%",
        },
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "SUM OF DEAL VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 80,
        },
        series: [],
      },
    };
  },

  averageDealValueByModels: () => {
    return {
      title: "Average deal value by models",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF DEAL VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 80,
        },
        series: [],
      },
    };
  },

  averageDealValueByProductTypes: () => {
    return {
      title: "Average deal value by product types",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF DEAL VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 80,
        },
        series: [],
      },
    };
  },

  averageDealValueByReps: () => {
    return {
      title: "Average deal value by reps",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF DEAL VALUE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 80,
        },
        series: [],
      },
    };
  },

  averagePricePerSquareFoot: () => {
    return {
      title: "Average price per square foot",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        chartType: "line",
        grid: {
          right: "5%",
          top: "5%",
        },
        xAxis: {
          data: [],
        },
        yAxis: {
          name: "SUM OF TOTAL PRICE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 70
        },
        series: [],
      },
    };
  },

  squareFootByModelTypes: () => {
    return {
      title: "Average price per square foot by model types",
      amount: 0,
      amountPrefix: "$",
      average: true,
      link: "sales/details",
      option: {
        chartType: "bar",
        xAxis: {
          type: "category",
          boundaryGap: true,
          data: [],
        },
        yAxis: {
          name: "SUM OF TOTAL PRICE",
          axisLabel: {
            formatter: "${value}",
          },
          nameGap: 70
        },
        series: [],
      },
    };
  },
};
