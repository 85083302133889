<template>
	<div class="px-4 w-full dF fC f1 pb-4 hide-scrollbar" style="overflow-y: scroll">
		<div>
			<a-tabs size="large" default-active-key="1">
				<div slot="tabBarExtraContent" class="dF aC" style="gap: 25px">
					<div v-if="corporateInstance" class="dF aC" style="gap: 5px">
						<span>Projects:</span>
						<SelectProjects class="ml-2" />
					</div>
					<div class="dF aC" style="gap: 5px">
						<span>Print:</span>
						<a @click.prevent="downloadPDF" href="javascript:;" class="text-primary">
							Charts
						</a>
						<PDFOrientation class="ml-2" />
					</div>
					<div class="dF" style="gap: 5px">
						<span>Download:</span>
						<a @click.prevent="getCSV" href="javascript:;" class="text-primary">CSV</a>
					</div>

					<DatePicker @current-times-update=" fetchChartData($event, 'currentData')"
						@compare-times-update=" fetchChartData($event, 'compareData')" />
				</div>
				<a-tab-pane key="1" tab="Overview">
					<vue-html2pdf :show-layout="true" :float-layout="false" :enable-download="false" :preview-modal="false"
						pdf-content-width="100%" :manual-pagination="true" :html-to-pdf-options="{
							margin: pdfOrientation === 'portrait' ? [50.2, 10, 50.2, 10] : [9, 50, 5, 50],
							image: { type: 'jpeg', quality: 2 },
							enableLinks: true,
							html2canvas: { scale: 1, useCORS: true },
							jsPDF: {
								orientation: pdfOrientation
							}
						}" @beforeDownload="beforeDownload($event)" ref="html2Pdf">
						<section slot="pdf-content">
							<div v-if="!error" class="report-grid">
								<ChartCard class="html2pdf__page-break" :details="closedDeals" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="closedDealsByModel" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="closedDealsByProductTypes"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="closedDealsByReps" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="salesRevenue" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="salesRevenueByModel" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="salesRevenuesByProductTypes"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="salesRevenuesByReps" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="premiumSalesRevenue" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="premiumSalesRevenueByTypes"
									:loading="loading" />
								<ChartCard v-if="productType === 'highrise'" class="html2pdf__page-break"
									:details="addOnSalesRevenue" :loading="loading" />
								<ChartCard v-if="productType === 'highrise'" class="html2pdf__page-break"
									:details="addOnSalesRevenueByTypes" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averageDealValue" :loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averageDealValueByModels"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averageDealValueByProductTypes"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averageDealValueByReps"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="averagePricePerSquareFoot"
									:loading="loading" />
								<ChartCard class="html2pdf__page-break" :details="squareFootByModelTypes"
									:loading="loading" />
							</div>
							<div v-else class="mt-10" style="text-align: center">
								<h4>Something went wrong. Please Try again!</h4>
								<a-button type="primary" @click="fetchChartData">Retry</a-button>
							</div>
						</section>
					</vue-html2pdf>
				</a-tab-pane>
			</a-tabs>
		</div>
	</div>
</template>

<script>
import ChartCard from "@/components/charts/chartCard";
import charts from "./chartData";
import moment from "moment";
import DatePicker from "@/components/charts/datePicker.vue";
import PDFOrientation from "@/components/charts/pdfOrientation.vue";
import SelectProjects from "@/components/charts/selectProjects.vue";
import { mapState, mapMutations } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
import { formatDate } from 'bh-mod'

export default {
	components: {
		ChartCard,
		DatePicker,
		VueHtml2pdf,
		PDFOrientation,
		SelectProjects
	},
	data: () => ({
		loading: false,
		error: false,
		salesReport: {
			currentData: {},
			compareData: {},
		},
	}),
	computed: {
		...mapState([
			"currentStartDate",
			"currentEndDate",
			"compareStartDate",
			"compareEndDate",
			"instance",
			"records",
		]),

		pdfOrientation() {
			return this.$store.state.pdfOrientation
		},

		corporateInstance() {
			return (this.instance && this.instance.projectType === 'corporate')
		},

		chartTitles() {
			if (this.productType === "lowrise") {
				return [
					'Total closed deals',
					'Total closed deals by models',
					'Total closed deals by product types',
					'Total closed deals by reps',
					'Total sales revenue',
					'Total sales revenue by models',
					'Total sales revenue by product types',
					'Total sales revenue by reps',
					'Total premium sales revenue',
					'Total premium sales revenue by types',
					'Average deal value',
					'Average deal value by models',
					'Average deal value by product types',
					'Average deal value by reps',
					'Average price per square foot',
					'Average price per square foot by model types'
				]
			} else {
				return [
					'Total closed deals',
					'Total closed deals by units',
					'Total closed deals by unit types',
					'Total closed deals by reps',
					'Total sales revenue',
					'Total sales revenue by units',
					'Total sales revenue by unit types',
					'Total sales revenue by reps',
					'Total premium sales revenue',
					'Total premium sales revenue by types',
					'Total add on sales revenue',
					'Total add on sales revenue by types',
					'Average deal value',
					'Average deal value by unit',
					'Average deal value by unit types',
					'Average deal value by reps',
					'Average price per square foot',
					'Averages price per square foot by unit types'
				]
			}
		},

		productType() {
			return this.instance.productType || "lowrise";
		},

		closedDeals() {
			const details = charts.closedDeals();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalClosedDeals"),
			};
		},

		closedDealsByModel() {
			const details = charts.closedDealsByModel();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails("totalClosedByModels"),
				};
			}
			return {
				...details,
				title: "Total closed deals by units",
				chartDetails: this.getChartDetails("totalClosedByUnits"),
			};
		},

		closedDealsByProductTypes() {
			const details = charts.closedDealsByProductTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails(
						"totalClosedByProductTypes"
					),
				};
			}
			return {
				...details,
				title: "Total closed deals by unit types",
				chartDetails: this.getChartDetails("totalClosedByUnitTypes"),
			};
		},

		closedDealsByReps() {
			const details = charts.closedDealsByReps();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalClosedByReps"),
			};
		},

		salesRevenue() {
			const details = charts.salesRevenue();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalSalesRevenue"),
			};
		},

		salesRevenueByModel() {
			const details = charts.salesRevenueByModel();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails(
						"totalSalesRevenueByModels"
					),
				};
			}
			return {
				...details,
				title: "Total sales revenue by units",
				chartDetails: this.getChartDetails("totalSalesRevenueByUnits"),
			};
		},

		salesRevenuesByProductTypes() {
			const details = charts.salesRevenuesByProductTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getChartDetails(
						"totalSalesRevenueByProductTypes"
					),
				};
			}
			return {
				...details,
				title: "Total sales revenue by unit types",
				chartDetails: this.getChartDetails(
					"totalSalesRevenueByUnitTypes"
				),
			};
		},

		salesRevenuesByReps() {
			const details = charts.salesRevenuesByReps();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalSalesRevenueByReps"),
			};
		},

		premiumSalesRevenue() {
			const details = charts.premiumSalesRevenue();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalPremiumSalesRevenue"),
			};
		},

		premiumSalesRevenueByTypes() {
			const details = charts.premiumSalesRevenueByTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails(
					"totalPremiumSalesRevenueByTypes"
				),
			};
		},

		addOnSalesRevenue() {
			const details = charts.addOnSalesRevenue();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails("totalAddOnSalesRevenue"),
			};
		},

		addOnSalesRevenueByTypes() {
			const details = charts.addOnSalesRevenueByTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getChartDetails(
					"totalAddOnSalesRevenueByTypes"
				),
			};
		},

		averageDealValue() {
			const details = charts.averageDealValue();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getAverageChartDetails("averageDealValue"),
			};
		},

		averageDealValueByModels() {
			const details = charts.averageDealValueByModels();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getAverageChartDetails(
						"averageDealValueByModels"
					),
				};
			}
			return {
				...details,
				title: "Average deal value by unit",
				chartDetails: this.getAverageChartDetails(
					"averageDealValueByUnits"
				),
			};
		},

		averageDealValueByProductTypes() {
			const details = charts.averageDealValueByProductTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getAverageChartDetails(
						"averageDealValueByProductTypes"
					),
				};
			}
			return {
				...details,
				title: "Average deal value by unit types",
				chartDetails: this.getAverageChartDetails(
					"averageDealValueByUnitTypes"
				),
			};
		},

		averageDealValueByReps() {
			const details = charts.averageDealValueByReps();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getAverageChartDetails(
					"averageDealValueByReps"
				),
			};
		},

		averagePricePerSquareFoot() {
			const details = charts.averagePricePerSquareFoot();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			return {
				...details,
				chartDetails: this.getAverageChartDetails(
					"averagePricePerSquareFoot"
				),
			};
		},

		squareFootByModelTypes() {
			const details = charts.squareFootByModelTypes();
			if (this.corporateInstance) {
				details.option.chartColumn = 'stack'
			}
			if (this.productType === "lowrise") {
				return {
					...details,
					chartDetails: this.getAverageChartDetails(
						"averagePricePerSquareFootByModelTypes"
					),
				};
			}
			return {
				...details,
				title: "Averages price per square foot by unit types",
				chartDetails: this.getAverageChartDetails(
					"averagePricePerSquareFootByUnitTypes"
				),
			};
		},
		filename() {
			return `Sale Report(${moment(this.currentStartDate).format(
				"ll"
			)} - ${moment(this.currentEndDate).format("ll")}${this.compareStartDate && this.compareEndDate
				? " vs " +
				moment(this.compareStartDate).format("ll") +
				" - " +
				moment(this.compareEndDate).format("ll")
				: ""
				})`;
		},

		salesRecords() {
			let records = [...this.records.sales]
			return records.map((s) => {
				return {
					...s,
					offerDate: s.offerDate && formatDate(s.offerDate) || "",
					closingDate: s.closingDate && formatDate(s.closingDate) || "",
					purchaseDate: s.purchaseDate && formatDate(s.purchaseDate) || "",
				}
			})
		}
	},

	methods: {
		...mapMutations(["setCurrentDates", "setCompareDates", "setRecords"]),

		getChartDetails(chartType) {
			const chartDetails = [];
			if (this.salesReport.currentData[chartType]) {
				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: this.salesReport.currentData[chartType],
				});
			}

			if (this.salesReport.compareData[chartType]) {
				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: this.salesReport.compareData[chartType],
				});
			}
			return chartDetails;
		},

		getAverageChartDetails(chartType) {
			const chartDetails = [];

			const currentDeals = this.salesReport.currentData[chartType];
			if (currentDeals) {
				const currentData = {};
				if (this.corporateInstance) {
					for (let key in currentDeals) {
						for (let instance in currentDeals[key]) {
							const count = currentDeals[key][instance].count;
							const price = currentDeals[key][instance].price;
							if (!currentData[key]) {
								currentData[key] = {};
							}
							if (!currentData[key][instance]) {
								currentData[key][instance] = 0;
							}
							currentData[key][instance] = Number(
								(price / count).toLocaleString("en-US", {
									useGrouping: false,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								})
							);
						}
					}
				} else {
					for (let key in currentDeals) {
						const count = currentDeals[key].count;
						const price = currentDeals[key].price;
						currentData[key] = Number(
							(price / count).toLocaleString("en-US", {
								useGrouping: false,
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							})
						);
					}
				}


				chartDetails.push({
					startDate: this.currentStartDate,
					endDate: this.currentEndDate,
					data: currentData,
				});
			}

			const compareDeals = this.salesReport.compareData[chartType];
			if (compareDeals) {
				const compareData = {};
				if (this.corporateInstance) {
					for (let key in compareDeals) {
						for (let instance in compareDeals[key]) {
							const count = compareDeals[key][instance].count;
							const price = compareDeals[key][instance].price;
							if (!compareData[key]) {
								compareData[key] = {};
							}
							if (!compareData[key][instance]) {
								compareData[key][instance] = 0;
							}
							compareData[key][instance] = Number(
								(price / count).toLocaleString("en-US", {
									useGrouping: false,
									minimumFractionDigits: 0,
									maximumFractionDigits: 2,
								})
							);
						}
					}
				} else {
					for (let key in compareDeals) {
						const count = compareDeals[key].count;
						const price = compareDeals[key].price;
						compareData[key] = Number(
							(price / count).toLocaleString("en-US", {
								useGrouping: false,
								minimumFractionDigits: 0,
								maximumFractionDigits: 2,
							})
						);
					}
				}

				chartDetails.push({
					startDate: this.compareStartDate,
					endDate: this.compareEndDate,
					data: compareData,
				});
			}

			return chartDetails;
		},

		fetchChartData(
			{
				startDate = this.currentStartDate,
				endDate = this.currentEndDate,
			},
			dateType = "currentData"
		) {
			this.error = false;
			this.loading = true;

			if (dateType === "currentData") {
				this.setCurrentDates({ startDate, endDate });
			} else {
				this.setCompareDates({ startDate, endDate });
			}

			if (!startDate && !endDate) {
				this.salesReport[dateType] = {};
				this.loading = false;
				this.$forceUpdate();
				return;
			}

			this.$api
				.post(`/daily-reports/${this.$store.state.instance.id}/graph`, {
					type: "sales",
					start: startDate,
					end: endDate,
					pType: this.productType,
				})
				.then(({ data }) => {
					this.setRecords({
						records: data.records,
						type: "sales",
					});
					delete data.records;
					this.salesReport[dateType] = data;
					this.loading = false;
				})
				.catch((error) => {
					console.error(error);
					this.error = true;
					this.loading = false;
				});
		},

		downloadPDF() {
			this.$nprogress.start();
			this.$refs.html2Pdf.generatePdf();
		},

		async beforeDownload({ html2pdf, options, pdfContent }) {
			options.filename = this.filename;
			await html2pdf()
				.set(options)
				.from(pdfContent)
				.toPdf()
				.get("pdf")
				.then((pdf) => {
					const totalPages = pdf.internal.getNumberOfPages();
					for (let i = 1; i <= totalPages; i++) {
						pdf.setPage(i);
						pdf.setFontSize(11);
						pdf.text(
							"Page " + i,
							pdf.internal.pageSize.getWidth() * 0.88,
							10
						);

						pdf.setFontSize(16);

						const title = this.chartTitles[i - 1] || ''
						pdf.text(
							title,
							(pdf.internal.pageSize.getWidth() * 0.44) - title.length,
							(this.pdfOrientation === 'portrait' ? 25 : 10)
						);
					}
				})
				.save()
				.then(() => {
					this.$nprogress.done();
				})
				.catch(() => {
					this.$nprogress.done();
				});
		},
		getCSV() {
			if (this.salesRecords.length) {
				let csv = Object.keys(this.salesRecords[0]).join(",");
				csv += "\n";

				this.salesRecords.forEach((row) => {
					csv += Object.values(row)
						.map((r) => {
							if (typeof r === "string") {
								return r.replaceAll(", ", " & ");
							}
							return r;
						})
						.join(",");
					csv += "\n";
				});

				const anchor = document.createElement("a");
				anchor.href =
					"data:text/csv;charset=utf-8," + encodeURIComponent(csv);
				anchor.target = "_blank";
				anchor.download = `${this.filename}.csv`;
				anchor.click();
			} else {
				this.$message.info("No records found!");
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.report-grid {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	gap: 20px;

	@media screen and (max-width: 1279px) {
		grid-template-columns: repeat(1, minmax(0, 1fr));
	}
}

.ant-select-selection__placeholder {
	color: var(--primary);
}
</style>
